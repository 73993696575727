import React from "react";
import styled from "styled-components";
import GitHub from "@material-ui/icons/GitHub";
import Pinterest from "@material-ui/icons/Pinterest";
import { urlFor } from "../client";
import { motion } from "framer-motion";
import { photoAnim } from "../animation";

function Menu({ menuItem }) {
  return (
    <MenuItemStyled>
      {menuItem.map((item, index) => {
        return (
          <div className="grid-item" key={index}>
            <div className="portfolio-content">
              <div className="portfolio-image">
                <Hide>
                  <motion.img
                    variants={photoAnim}
                    src={urlFor(item.imgUrl)}
                    alt={item.title}
                  />

                  <ul>
                    <li>
                      <a href={item.codeLink} rel="noreferrer" target="_blank">
                        <GitHub />
                      </a>
                    </li>
                    <li>
                      <a
                        href={item.projectLink}
                        rel="noreferrer"
                        target="_blank"
                      >
                        <Pinterest />
                      </a>
                    </li>
                  </ul>
                </Hide>
              </div>
              <h6>{item.title}</h6>
              <p>{item.description}</p>
            </div>
          </div>
        );
      })}
    </MenuItemStyled>
  );
}

const MenuItemStyled = styled(motion.div)`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 2rem;
  @media screen and (max-width: 920px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media screen and (max-width: 670px) {
    grid-template-columns: repeat(1, 1fr);
  }
  .grid-item {
    .portfolio-content {
      display: block;
      position: relative;
      overflow: hidden;
      h6 {
        font-size: 1.5rem;
      }
      img {
        width: 100%;
        height: 30vh;
        object-fit: cover;
      }
      ul {
        transform: translateY(-600px);
        transition: all 0.4s ease-in-out;
        position: absolute;
        left: 50%;
        top: 40%;
        opacity: 0;
        li {
          background-color: var(--border-color);
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 1rem;
          border-radius: 50%;
          width: 3rem;
          height: 3rem;
          margin: 0 0.5rem;
          transition: all 0.4s ease-in-out;
          &:hover {
            background-color: var(--primary-color);
          }
          a {
            display: flex;
            align-items: center;
            justify-content: center;
            transition: all 0.4s ease-in-out;
          }
        }
      }

      .portfolio-image:hover {
        ul {
          transform: translateY(0);
          transform: translate(-50%, 0);
          display: flex;
          align-items: center;
          justify-content: center;
          transition: all 0.4s ease-in-out;
          opacity: 1;
          li {
            transition: all 0.4s ease-in-out;
            &:hover {
              background-color: var(--primary-color);
            }
            a {
              display: flex;
              align-items: center;
              justify-content: center;
              transition: all 0.4s ease-in-out;
            }
          }

          li:hover {
            svg {
              color: var(--white-color);
            }
          }
          svg {
            font-size: 2rem;
          }
        }
      }
    }
  }
`;
const Hide = styled.div`
  /* width: 500px; */
  color: white;
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
  overflow: hidden;
  &:hover::before {
    transform: translateY(0);
  }
  &::before {
    transition-delay: 0s;

    content: "";
    position: absolute;

    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #005e66;
    opacity: 0.5;
    transform: translateY(100%);
    transition: all 0.25s ease-in-out;
    transition-delay: 0.25s;
  }
  &:hover {
    ul {
      opacity: 1;
      transform: translateY(0);
      transition-delay: 0.3s;
    }
  }
`;

export default Menu;
