import React, { useEffect } from "react";
import Vivus from "vivus";

const TitleAnim1 = () => {
  useEffect(() => {
    new Vivus("main-title", {
      duration: 200,
      file: "SVG/DhruvNight.svg",
    });
  }, []);

  return <div id="main-title"></div>;
};

export default TitleAnim1;
