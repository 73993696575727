import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import ReviewItem from "../Components/ReviewItem";
import { client } from "../client";

function ReviewsSetion() {
  const [reviews, setReviews] = useState([]);
  useEffect(() => {
    const query = '*[_type == "reviews"]';

    client.fetch(query).then((data) => {
      setReviews(data);
    });
  }, []);
  return (
    <ReviewsStyled>
      <Title title={"Reviews"} span={"Reviews"} />
      <InnerLayout>
        <div className="reviews">
          {reviews.map((review, index) => (
            <ReviewItem key={index} text={review.review} />
          ))}
        </div>
      </InnerLayout>
    </ReviewsStyled>
  );
}

const ReviewsStyled = styled.section`
  .reviews {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1.5rem;
    width: 100%;
    @media screen and (max-width: 650px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default ReviewsSetion;
