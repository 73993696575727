import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import ProgressBar from "./ProgressBar";
import Cube from "./cube/Cube";
import { client } from "../client";

function Skills(props) {
  const [skills, setSkills] = useState([]);
  useEffect(() => {
    const query = '*[_type == "skills"]';

    client.fetch(query).then((data) => {
      setSkills(data);
    });
  }, []);
  return (
    <SkillsStyled>
      <Title title={"My Skills"} span={"my skills"} />
      <Cube dn={props.dn} />
      <InnerLayout>
        <div className="skills">
          {skills.map((skill, index) => (
            <ProgressBar
              key={index}
              title={skill.name}
              width={skill.level}
              text={skill.level}
            />
          ))}
        </div>
      </InnerLayout>
    </SkillsStyled>
  );
}

const SkillsStyled = styled.section`
  .skills {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-row-gap: 2rem;
    grid-column-gap: 3rem;
    @media screen and (max-width: 700px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
`;

export default Skills;
