import React, { useEffect } from "react";
import Vivus from "vivus";

const HiAnim2 = () => {
  useEffect(() => {
    new Vivus("main-title", {
      duration: 200,
      file: "SVG/Hiday.svg",
    });
  }, []);

  return <div id="main-title"></div>;
};

export default HiAnim2;
