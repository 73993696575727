import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import SmallTitle from "../Components/SmallTitle";
import BusinessCenterIcon from "@material-ui/icons/BusinessCenter";
import SchoolIcon from "@material-ui/icons/School";
import ResumeItem from "../Components/ResumeItem";
import { client } from "../client";

function Resume() {
  const [experiences, setExperiences] = useState([]);
  const [qualifications, setQualification] = useState([]);
  useEffect(() => {
    const query1 = '*[_type == "workExperience"]';
    const query2 = '*[_type == "qualification"]';

    client.fetch(query1).then((data) => {
      setExperiences(data);
    });
    client.fetch(query2).then((data) => {
      setQualification(data);
    });
  }, []);

  const briefcase = <BusinessCenterIcon />;
  const school = <SchoolIcon />;
  return (
    <ResumeStyled>
      <Title title={"Resume"} span={"resume"} />
      <InnerLayout>
        <div className="small-title">
          <SmallTitle icon={briefcase} title={"Working Experience"} />
        </div>
        <div className="resume-content">
          {experiences.map((experience, index) => (
            <ResumeItem
              key={index}
              year={experience.year}
              title={experience.name}
              subTitle={experience.subtitle}
              text={experience.desc}
            />
          ))}
        </div>
        <div className="small-title u-small-title-margin">
          <SmallTitle icon={school} title={"Educational Qualifications"} />
        </div>
        <div className="resume-content">
          {qualifications.map((qualification, index) => (
            <ResumeItem
              key={index}
              year={qualification.year}
              title={qualification.name}
              subTitle={qualification.subtitle}
              text={qualification.desc}
            />
          ))}
        </div>
      </InnerLayout>
    </ResumeStyled>
  );
}

const ResumeStyled = styled.section`
  .small-title {
    padding-bottom: 3rem;
  }
  .u-small-title-margin {
    margin-top: 4rem;
  }

  .resume-content {
    border-left: 2px solid var(--border-color);
  }
`;
export default Resume;
