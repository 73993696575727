import styled from "styled-components";
import { motion } from "framer-motion";

export const MainLayout = styled(motion.div)`
  padding: 5rem;
  @media screen and (max-width: 642px) {
    padding: 4rem;
  }
  /* @media screen and (max-width: 510px){
        padding: 3rem;
    } */
  @media screen and (max-width: 571px) {
    padding: 2rem 1.5rem;
  }
`;
export const InnerLayout = styled.div`
  padding: 5rem 0;
`;
