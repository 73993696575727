import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Title from "../Components/Title";
import { MainLayout, InnerLayout } from "../styles/Layouts";
import { client, urlFor } from "../client";
import { pageAnimation } from "../animation";
import { motion } from "framer-motion";
import { photoAnim } from "../animation";

function BlogsPage(props) {
  const [blogs, setBlogs] = useState([]);
  useEffect(() => {
    const query = '*[_type == "blogs"]';

    client.fetch(query).then((data) => {
      setBlogs(data);
    });
  }, []);

  useEffect(() => {
    if (props.navToggle) {
      props.setNavToggle(!props.navToggle);
    }
  }, []);

  return (
    <MainLayout
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <BlogsStyled>
        <Title title={"Blogs"} span={"Blogs"} />
        <InnerLayout className={"blog"}>
          {blogs.map((blog, i) => {
            return (
              <div key={i} className={"blog-item"}>
                <div className="image">
                  <Hide>
                    <motion.img
                      variants={photoAnim}
                      src={urlFor(blog.imgUrl)}
                      alt={blog.title}
                    />
                  </Hide>
                </div>
                <div className="title">
                  <a href={blog.blogLink} rel="noreferrer" target="_blank">
                    {blog.title}
                  </a>
                </div>
              </div>
            );
          })}
        </InnerLayout>
      </BlogsStyled>
    </MainLayout>
  );
}

const BlogsStyled = styled.div`
  .blog {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 2rem;
    grid-row-gap: 3rem;
    @media screen and (max-width: 770px) {
      grid-template-columns: repeat(1, 1fr);
    }
    .blog-item {
      background-color: var(--background-dark-grey);
      padding: 1rem 1rem;
    }
    .image {
      width: 100%;
      overflow: hidden;
      padding-bottom: 0.5rem;
      img {
        width: 100%;
        height: 90%;
        object-fit: cover;
        transition: all 0.4s ease-in-out;
        &:hover {
          cursor: pointer;
          transform: rotate(3deg) scale(1.1);
        }
      }
    }

    .title {
      a {
        font-size: 1.8rem;
        padding: 2rem 0;
        color: var(--white-color);
        cursor: pointer;
        transition: all 0.4s ease-in-out;
        &:hover {
          color: var(--primary-color);
        }
      }
    }
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;
export default BlogsPage;
