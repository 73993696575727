import React, { useState, useEffect } from "react";
import { MainLayout, InnerLayout } from "../styles/Layouts";
import Title from "../Components/Title";
import Menu from "../Components/Menu";
import Button from "../Components/Button";
import { client } from "../client";
import { pageAnimation } from "../animation";

function PortfoliosPage(props) {
  const [menuItem, setMenuItems] = useState([]);
  const [button, setButtons] = useState([]);
  const [portfolios, setPortfolios] = useState([]);
  useEffect(() => {
    const query = '*[_type == "works"]';

    client.fetch(query).then((data) => {
      setPortfolios(data);
      setButtons(["All", ...new Set(data.map((item) => item.tags[0]))]);
    });
  }, []);

  const filter = (button) => {
    if (button === "All") {
      setMenuItems(portfolios);
      return;
    }

    const filteredData = portfolios.filter((item) => item.tags[0] === button);
    setMenuItems(filteredData);
  };

  useEffect(() => {
    filter("All");
  }, [button]);

  useEffect(() => {
    if (props.navToggle) {
      props.setNavToggle(!props.navToggle);
    }
  }, []);
  return (
    <MainLayout
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Title title={"Portfolios"} span={"portfolios"} />
      <InnerLayout>
        <Button filter={filter} button={button} />
        <Menu menuItem={menuItem} />
      </InnerLayout>
    </MainLayout>
  );
}

export default PortfoliosPage;
