import React, { useEffect } from "react";
import Skills from "../Components/Skills";
import { MainLayout } from "../styles/Layouts";
import Resume from "../Components/Resume";
import { pageAnimation } from "../animation";

function ResumePage(props) {
  useEffect(() => {
    if (props.navToggle) {
      props.setNavToggle(!props.navToggle);
    }
  }, []);
  return (
    <MainLayout
      variants={pageAnimation}
      initial="hidden"
      animate="show"
      exit="exit"
    >
      <Skills dn={props.dn} />
      <Resume />
    </MainLayout>
  );
}

export default ResumePage;
