import React, { useEffect } from "react";
import styled from "styled-components";
import FacebookIcon from "@material-ui/icons/Facebook";
import GithubIcon from "@material-ui/icons/GitHub";
import YoutubeIcon from "@material-ui/icons/YouTube";
import LinkedInIcon from "@material-ui/icons/LinkedIn";
import Particle from "../Components/Particle";
import TitleAnim1 from "../Components/TitleAnim1";
import TitleAnim2 from "../Components/TitleAnim2";
import HiAnim2 from "../Components/HiAnim2";
import HiAnim1 from "../Components/HiAnim1";
import dynamic from "next/dynamic";
import VoxelDogLoader from "../Components/voxel-dog-loader";

const LazyVoxelDog = dynamic(() => import("../Components/voxel-dog"), {
  ssr: false,
  loading: () => <VoxelDogLoader />,
});

function HomePage(props) {
  useEffect(() => {
    if (props.navToggle) {
      props.setNavToggle(!props.navToggle);
    }
  }, []);
  return (
    <HomePageStyled>
      <div className="particle-con">
        <Particle dn={props.dn} />
      </div>

      <div className="typography">
        {/* <h1>
          Hi, I'm <span>Dhruv Mojila</span>
        </h1> */}
        <div className="title">
          <LazyVoxelDog />
          {/* <HiDay />
          <DhruvDay /> */}
          {props.dn === "dark-theme" ? (
            <>
              <HiAnim1 className="hi" />
              <TitleAnim1 className="dh" />
            </>
          ) : (
            <>
              <HiAnim2 className="hi" />
              <TitleAnim2 className="dh" />
            </>
          )}
        </div>

        <div className="icons">
          <a
            href="https://en-gb.facebook.com/dhruv.mojila"
            className="icon i-facebook"
            target="_blank"
            rel="noreferrer"
          >
            <FacebookIcon />
          </a>
          <a
            href="https://github.com/dhruvmojila"
            className="icon i-github"
            target="_blank"
            rel="noreferrer"
          >
            <GithubIcon />
          </a>
          <a
            href="https://www.linkedin.com/in/dhruv-mojila-ab101a205/"
            className="icon i-facebook"
            target="_blank"
            rel="noreferrer"
          >
            <LinkedInIcon />
          </a>
          <a
            href="https://www.youtube.com/channel/UCSJYK26IhU_pPJkLjIXQjpQ/featured"
            className="icon i-youtube"
            target="_blank"
            rel="noreferrer"
          >
            <YoutubeIcon />
          </a>
        </div>
      </div>
    </HomePageStyled>
  );
}

// const NewDog = styled("LazyVoxelDog")`
//   position: fixed;
//   top: 50%;
//   left:50%;
// `;

const HomePageStyled = styled.header`
  width: 100%;
  height: 100vh;
  position: relative;

  .typography {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;

    .title {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      margin: 1.5rem;
      .hi {
        width: 19%;
        stroke-width: 1.7;
        /* margin: 1rem; */
        margin-top: 2rem;
      }
      .dh {
        /* margin: 1rem; */
        width: 100%;
        stroke-width: 3;
        animation-delay: 3s;
      }
      .voxel-dog {
        width: 80vh;
        height: 80vh;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: -150px;
      }
      @media screen and (max-width: 571px) {
        .voxel-dog {
          width: 34vh;
          height: 34vh;
          margin-bottom: -60px;
        }

        margin: 1rem;
        .hi {
          width: 16%;
          stroke-width: 1.7;
          margin: 1rem;
        }
        .dh {
          width: 90%;
        }
      }
    }

    .icons {
      display: flex;
      justify-content: center;
      margin-top: 1rem;
      .icon {
        border: 2px solid var(--border-color);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        transition: all 0.4s ease-in-out;
        cursor: pointer;
        &:hover {
          border: 2px solid var(--primary-color);
          color: var(--primary-color);
        }
        &:not(:last-child) {
          margin-right: 1rem;
        }
        svg {
          margin: 0.5rem;
        }
      }

      .i-youtube {
        &:hover {
          border: 2px solid red;
          color: red;
        }
      }
      .i-github {
        &:hover {
          border: 2px solid #5f4687;
          color: #5f4687;
        }
      }
    }
  }
`;

export default HomePage;
