import sanityClient from "@sanity/client";
import imageUrlBuilder from "@sanity/image-url";

export const client = sanityClient({
  projectId: `dlln7yro`,
  dataset: "production",
  apiVersion: "2022-03-13",
  useCdn: true,
  token: `skIQuIMiycOIAoIFM5mRhME22ktB2FwJhUyLrywyMdEie4Y8UnjBd6v6Z8gSIfUosgX986JYjMdgl2EGS1ulpQnUvEFUrpu33UxZTKP4QdSLh9Baoh93LB7Zyls5CSTbxxUVVsXIWnyqJXvQ0L0VHfxtECXU6wXC20EWSAmNaQL2SPkKrGa7`,
});

const builder = imageUrlBuilder(client);

export const urlFor = (source) => builder.image(source);
