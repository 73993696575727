import React from "react";
import styled from "styled-components";
import resume from "../img/resume.JPG";
import PrimaryButton from "./PrimaryButton";
import { motion } from "framer-motion";
import { photoAnim } from "../animation";

function ImageSection() {
  return (
    <ImageSectionStyled>
      <div className="left-content">
        <Hide>
          <motion.img variants={photoAnim} src={resume} alt="Dhruv Mojila" />
        </Hide>
      </div>
      <div className="right-content">
        <h4>
          I am <span>Dhruv Mojila</span>
        </h4>
        <p className="paragraph">
          I'm currently in 3rd year of computer science and engineering. I'm a
          coding geek ,From my childhood itself I have been very much excited to
          learn new things and when it comes to the technology I get super
          excited. I also enjoy sketching and painting in my free time.
        </p>
        <div className="about-info">
          <div className="info-title">
            <table>
              <tbody>
                <tr>
                  <td>Full Name </td>
                  <td>Dhruv Rakeshkumar Mojila</td>
                </tr>
                <tr>
                  <td>Age </td>
                  <td>21</td>
                </tr>
                <tr>
                  <td>Nationality </td>
                  <td>Indian</td>
                </tr>
                <tr>
                  <td>Languages </td>
                  <td>Gujrati, Hindi, English</td>
                </tr>
                <tr>
                  <td>Location </td>
                  <td>Surat, Gujrat, India.</td>
                </tr>
                <tr>
                  <td>Service </td>
                  <td>Freelancing</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <PrimaryButton title={"Download Cv"} />
      </div>
    </ImageSectionStyled>
  );
}

const ImageSectionStyled = styled.div`
  margin-top: 5rem;
  display: flex;
  @media screen and (max-width: 1000px) {
    flex-direction: column;
    .left-content {
      margin-bottom: 2rem;
    }
  }
  .left-content {
    width: 100%;
    img {
      width: 95%;
      object-fit: cover;
    }
    @media screen and (max-width: 800px) {
      img {
        width: 100%;
      }
    }
  }
  .right-content {
    width: 100%;
    h4 {
      font-size: 2rem;
      color: var(--white-color);
      span {
        font-size: 2rem;
      }
    }
    .paragraph {
      padding: 1rem 0;
      text-align: justify;
    }
    .about-info {
      display: flex;
      padding-bottom: 1.4rem;
      width: 100%;
      .info-title {
        padding-right: 3rem;

        table {
          tbody {
            tr {
              td {
                @media screen and (max-width: 485px) {
                  width: 100%;
                }

                font-weight: 600;
              }
            }
          }
        }
      }
      .info-title,
      .info {
        table {
          tbody {
            tr {
              td {
                padding: 0.3rem 0.5rem;
              }
            }
          }
        }
      }
    }
  }
`;
const Hide = styled.div`
  overflow: hidden;
`;
export default ImageSection;
